.about {
  background: $white;
  padding: 50px 10px 50px 50px;
  font-size: 18px;
  line-height: 30px;

}

.about__header {
  color: $red;
  font-size: 30px;
  margin-bottom: 30px;
}

.about__position {
  font-style: italic;
  font-family: 'parangon_230';
}

.about__person {
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 15px;
  font-style: italic;
  font-family: 'parangon_420';
  margin-right: 40px;
  a {
    font-family: inherit;
    color: inherit;
    text-decoration: none;
  }
}

.about-partners {
  background: $blackGrey;
  padding: 50px 20px 150px 50px;
  color: $white;
  line-height: 30px;
  font-size: 18px;
}

.about-partners__head {
  font-family: 'parangon_230';
  font-style: italic;
}

.about-partners__name {
  font-weight: bold;
  font-family: 'parangon_420';
}

@include mediaTabletW {
  .about__person {
    font-size: 16px;
    width: 180px;
  }

  .about__position {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 10px;
  }

  .about-partners__head {
    font-size: 16px;
  }

  .about-partners__name {
    font-size: 16px;
  }
}

@include mediaMobileW {
  .about, .about-partners {
    padding: 30px 20px 30px;
  }

  .about__position {
    margin-bottom: 0;
  }

}
