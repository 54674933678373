@font-face {
  font-family: 'parangon_110c';
  src: url('../fonts/parangon/PGN39.otf');
}

@font-face {
  font-family: 'parangon_120c';
  src: url('../fonts/parangon/PGN37.otf');
}

@font-face {
  font-family: 'parangon_210c';
  src: url('../fonts/parangon/PGN49.otf');
}

@font-face {
  font-family: 'parangon_310c';
  src: url('../fonts/parangon/PGN59.otf');
}

@font-face {
  font-family: 'parangon_410c';
  src: url('../fonts/parangon/PGN69.otf');
}

@font-face {
  font-family: 'parangon_510c';
  src: url('../fonts/parangon/PGN79.otf');
}

@font-face {
  font-family: 'parangon_130';
  src: url('../fonts/parangon/PGT35.otf');
}

@font-face {
  font-family: 'parangon_220';
  src: url('../fonts/parangon/PGT47.otf');
}

@font-face {
  font-family: 'parangon_230';
  src: url('../fonts/parangon/PGT45.otf');
}

@font-face {
  font-family: 'parangon_320';
  src: url('../fonts/parangon/PGT57.otf');
}

@font-face {
  font-family: 'parangon_330';
  src: url('../fonts/parangon/PGT55.otf');
}

@font-face {
  font-family: 'parangon_420';
  src: url('../fonts/parangon/PGT67.otf');
}

@font-face {
  font-family: 'parangon_430';
  src: url('../fonts/parangon/PGT65.otf');
}

@font-face {
  font-family: 'parangon_520';
  src: url('../fonts/parangon/PGT77.otf');
}

@font-face {
  font-family: 'parangon_530';
  src: url('../fonts/parangon/PGT75.otf');
}

