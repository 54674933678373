* {
  box-sizing: border-box;
}

body {
  font-size: 16px;
}

.overflow {
  overflow: hidden;
}

.hide {
  display: none;
}

@import "mobile";

@include mediaMobileW {
  html, body {
    &.overflow {
      overflow: initial;
    }
  }
}