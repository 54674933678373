@import "../_components/about";

.o-sidebar {
  position: fixed;
  left: -$widthSidebar;
  top: 0;
  z-index: 15;
  width: $widthSidebar;
  height: 100%;
  background-color: black;
  transition: left $animateSidebar;
  will-change: left;

  .b-top-menu__ul {
    top: 9px;
    position: relative;
    height: $heightHeader;
  }
}

.sidebar-open {
  .o-sidebar {
    left: 0;
  }

  .wrapper .o-main, .c-widget {
    opacity: .5;
  }

  .o-sidebar-frame{
    width: 100%;
    height: 100%;
    z-index: 14;
    position: fixed;
    top: 0;
    left: 0;
  }
}

.mCSB_outside + .mCSB_scrollTools {
  right: 0;
}

.o-sidebar_home {
  .b-top-menu__ul {
    top: 30px;
    position: relative;
    height: $heightHeaderHome;
  }
}


@include mediaTabletW {
  .o-sidebar {
    width: $widthSidebarSmall;

    .e-logo.e-logo_britishairways {
      display: none;
    }
    .b-top-menu_vedomosti {
      &:after {
        display: none;
      }
    }
  }
}

@include mediaMobileW {
  .o-sidebar {
    width: 100%;
    left: -100%;
    will-change: left;
    top: $heightHeaderMobile;
    z-index: 1;

    .e-logo.e-logo_britishairways {
      display: block;
    }
  }

  .sidebar-open {
    .o-sidebar {
      position: absolute;
    }

    .wrapper .o-main {
      position: fixed;
    }
  }
}