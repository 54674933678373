.mobile-footer{
  height: 220px;
  background: $darkGrey;
}

.mobile-footer__social{
  background: $redDarker;
  height: 69px;
}

.mobile-footer__social-wrapper{
  width: 118px;
  margin: 0 auto;
  padding: 20px 0 0;
  font-size: 0;
}

.mobile-footer__social-icon{
  display: inline-block;
  margin-left: 16px;

  &:first-child{
    margin-left: 0;
  }
}

.mobile-footer__banners-item{
  display: block;
  margin: 0 auto;

  &.e-logo_great-britain{
    background-size: 80px;
    width: 80px;
    height: 88px;
    top: 24px;
  }

  &.e-logo_britishairways{
    width: 184px;
    background-size: 156px;
    background-position: 29px center;
  }
}