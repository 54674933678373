.c-tagline {
	text-align: center;
	height: 100%;
}

.c-tagline__h-omgb {
	height: 100%;
	display: block;
	margin: 0 auto 44px;
}

.c-tagline__tagline{
	height: 22%;
	display: block;
	margin: 0 auto;
}

.c-tagline__tagline-text{
	text-transform: uppercase;
	font-size: 20px;
	@include font();
	padding: 0 60px;
	margin: auto;
	color: $white;
	display: none;
	font-family: "FuturaFuturisCondBoldC";
}

@include mediaMobileW{
	.c-tagline{
		position: absolute;
		width: 100%;
		top: 22%;
		.c-tagline__tagline-text {
			padding: 0 10px;
			transform: scaleY(.8);
		}
	}

	.c-tagline__h-omgb{
		height: 50px;
		margin-bottom: 15px;
	}
}