.o-gallery {
  * {
    box-sizing: border-box;
  }
  z-index: 10;
  top: 125%;
  position: fixed;
  width: 51000px;
  height: 100%;
  background-color: white;
  overflow: hidden;
  transition: top $animateTimeShowGallery;
  will-change: top;

  .b-top-menu_vedomosti {
    margin-top: -6px;
  }
  .b-top-menu__anchors {
    z-index: 0;
  }
  &.hidden {
  }

  &.show {
    top: 0;

    position: absolute;

    .b-top-menu.b-top-menu_black {
      top: 0;
    }

    &.show2 {
      .logo {
        opacity: 1;
      }
      .intro {
        .intro-content {
          right: 30px;

          @media only screen and (max-width: 480px) {
            right: 10px;
          }
        }

        .intro-text {
          opacity: 1;
        }
      }

      .b-top-menu_black .b-top-menu__social {
        transition: opacity $animateTimeShowGalleryPart2;
        opacity: 1;
      }
    }
  }

  .sidebar-btn {
    display: none;
  }

  a {
    text-decoration: underline;
    color: inherit;
  }

  a.e-gallerey.gallery-btn__close {
    background-image: url('/img/gallery-close.png');
  }

  a.e-gallerey.gallery-btn__close:hover {
    background-image: url('/img/gallery-close-hover.png');
  }

  a.e-gallerey.gallery-btn__close:active {
    background-image: url('/img/gallery-close-active.png');
  }

  p {
    position: relative;
    z-index: 3;
  }

  .b-top-menu.b-top-menu_black {
    position: fixed;
    right: 0;
    top: -62px;
  }

  .gallery-btn.gallery-btn_close {
    position: absolute;
    top: 11px;
    right: 12px;
    width: 68px;
  }

  .b-top-menu_black .b-top-menu__social {
    top: 77px;
    position: fixed;
    opacity: 0;
    display: none;
    right: 21px;
  }

  .info, .intro, .gallery-image {
    position: relative;
    display: block;
    float: left;
  }

  .intro {
    height: 100%;
    width: 960px;
    background: url("/img/gallery-intro.png") no-repeat;
    background-size: cover;

    .intro-frame {
      position: relative;
      height: 100%;
    }

    .intro-content {
      width: 450px;
      position: absolute;
      top: 30%;
      right: 110%;
      color: #ffffff;
      transition: right $animateTimeShowGalleryPart2;
      will-change: right;

      .intro-num {
        font-size: 206px;
        line-height: 0.7;
        letter-spacing: -20px;
        font-family: "parangon_420";
      }
      .intro-text {
        padding-left: 116px;
        opacity: 0;
        transition: opacity $animateTimeShowGalleryPart2 $animateDelayShowGalleryPart2;
        will-change: opacity;

        span {
          display: block;
          &:first-child {
            font-size: 38px;
            font-family: "parangon_530";
            text-transform: uppercase;
          }
          &:last-child {
            font-size: 32px;
            font-family: "parangon_230";
          }
        }
      }
    }

    @media only screen and (max-width: 480px) {
      .intro-content {
        width: 320px;

        .intro-num{
          font-size: 180px;
        }

        .intro-text{
          padding-left: 80px;

          span{
            &:first-child {
              font-size: 26px;
            }
            &:last-child {
              font-size: 24px;
            }
          }
        }
      }
    }
  }

  .gallery-image {
    height: 100%;
    width: auto;
  }

  .logo {
    width: 74px;
    height: 56px;
    position: fixed;
    right: 20px;
    bottom: 28px;
    opacity: 0;
    transition: opacity $animateTimeShowGalleryPart2 $animateDelayShowGalleryPart2;
    z-index: 3;
  }

  .avia:last-of-type {
    width: 1362px;
    height: auto;
  }

  .avia {
    position: relative;
    height: 100%;
    float: left;
    color: #fff;
    .avia-content {
      font-size: 14px;
      &:after {
        position: absolute;
        right: -24px;
        bottom: 45%;
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 23px 0 23px 24px;
        border-color: transparent transparent transparent rgba(0, 87, 164, 0.8);
        z-index: 10;
      }
      position: absolute;
      width: 534px;
      height: 100%;
      background-color: rgba(0, 87, 164, 0.8);
      .avia-content-header {
        position: relative;
        height: 130px;
        background-color: #0057a4;
        padding: 75px 10px 0 50px;
        text-transform: uppercase;
        font-size: 26px;
        span:first-child {
          font-family: "parangon_430";
        }
        span:nth-child(2) {
          font-family: "parangon_230";
        }
        .e-logo {
          position: absolute;
          right: 10px;
          bottom: 18px;
        }
      }
      .avia-content-info {
        padding: 50px 50px 0 50px;
        p {
          padding-bottom: 26px;
          font-family: "parangon_430";
          line-height: 19px;
        }
        ul {
          font-size: 14px;
          list-style-position: inside;
          font-family: "parangon_330";
          font-style: italic;
          line-height: 19px;

          li span {
            margin-left: -10px;
          }
        }

      }
      .avia-content-links {
        width: 427px;
        border-top: 1px solid #3772a7;
        padding-top: 36px;
        margin-top: 10px;
        margin-left: 50px;
        font-size: 16px;
        p {
          font-family: "parangon_430";
          margin-bottom: 25px;
        }
        a {
          color: #ffffff;
          font-family: "parangon_330";
          font-style: italic;
        }
      }
      .avia-content-footer {
        width: 427px;
        position: absolute;
        bottom: 62px;
        margin-left: 50px;
        margin-right: 50px;
        border-top: 1px solid #3772a7;
        padding: 42px 0 0 0;
        img {
          position: absolute;
          border: 2px solid #3772a7;
          padding: 9px;
          border-radius: 50%;
        }

        span {
          display: block;
          padding-left: 58px;
          font-size: 20px;
          font-family: "parangon_330";
          &.cost {
            font-size: 18px;
            font-family: "parangon_430";
          }
        }
      }
    }

    .btn {
      width: 192px;
      display: block;
      margin-top: 30px;
      background: white;
      color: #0057a4;
      border-radius: 30px;
      padding: 14px 40px;
      font-size: 14px;
      font-family: "parangon_430";
      font-style: italic;
      text-decoration: none;
      &:hover {
        background-color: #e11b21;
        color: #ffffff;
      }
      &:active {
        background-color: #171717;
        color: #ffffff;
      }
    }
    .avia-tickets {
      width: 530px;
      position: absolute;
      right: 138px;
      top: 32%;
      .avia-tickets-header {
        width: 530px;
        height: 156px;
        background-image: url('/img/logo.png');
      }
      .avia-tickets-content {
        background-color: rgba(0, 87, 164, 0.8);
        padding: 36px 50px 50px 50px;
        img {
          position: absolute;
          border: 2px solid #3772a7;
          padding: 9px;
          border-radius: 50%;
        }
        span {
          display: block;
          padding-left: 58px;
          padding-top: 10px;
          font-size: 20px;
          font-family: "parangon_330";
        }

        .cost {
          padding-top: 26px;
          &:after {
            content: ".";
            visibility: hidden;
            display: block;
            height: 0;
            clear: both;
          }
          .econom, .business {
            padding-top: 0px;
            width: 210px;
            float: left;
          }
          .econom {
            margin-right: 10px;
          }
          .business {
            padding-left: 32px;
            border-left: 1px solid #3772a7;
          }
        }
      }
    }
  }

  .business-images {
    float: left;
    height: 100%;
    img {
      display: block;
      width: auto;
      height: 50%;
    }
  }

  .info {
    font-family: "parangon_330";
    font-size: 12px;
    line-height: 20px;
    height: 100%;
    width: 530px;
    padding: 112px 52px 0 52px;
    background-color: white;
    h1 {
      font-family: "parangon_330";
      font-size: 26px;
      line-height: 36px;
      color: #df262a;
      margin-top: 0;
    }
    h2 {
      position: relative;
      z-index: 2;
      padding-bottom: 5px;
      font-size: 20px;
    }
    p {
      padding-bottom: 26px;
      span {
        font-family: "parangon_430";
        text-decoration: underline;
        font-style: italic;
      }
    }
    b {
      font-family: "parangon_430";
      font-weight: normal;
    }
    .number {
      font-family: "parangon_420";
      position: absolute;
      bottom: -32px;
      font-size: 206px;
      line-height: 248px;
      color: #ebebeb;
      margin-left: -40px;
    }

    &:after {
      position: absolute;
      right: -24px;
      bottom: 74px;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 23px 0 23px 24px;
      border-color: transparent transparent transparent #ffffff;
      z-index: 10;
    }
  }

  .info14 {
    position: relative;
    .a, .b, .c {
      position: absolute;
      width: 0;
      height: 0;
      right: -24px;
      border-style: solid;
      border-width: 23px 0 23px 24px;
      border-color: transparent transparent transparent #ffffff;
      z-index: 10;
      font-family: "parangon_430";
      font-size: 16px;
      span {
        display: block;
        position: absolute;
        left: -24px;
      }
    }
    .a {
      top: 17%;
      span {
        top: -9px;
      }
    }
    .b {
      top: 50%;
      span {
        top: -9px;
      }
    }
    .c {
      top: 83%;
      span {
        top: -9px;
      }
    }
    &:after {
      display: none;
    }
  }
}

.small-top, .small-bottom-offset, .small-top-offset, .small-middle-offset {
  position: relative;
  height: 100%;
  float: left;
  img {
    height: 100%;
  }
  .content {
    position: absolute;
    background: #ffffff;
    padding: 116px 54px 0 54px;
    h2 {
      font-family: "parangon_430";
      font-size: 20px;
    }
    p {
      padding-top: 24px;
      font-family: "parangon_330";
      font-size: 16px;
      line-height: 19px;
    }
    b {
      font-family: "parangon_430";
    }
    &:after {
      position: absolute;
      right: -24px;
      bottom: 74px;
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 23px 0 23px 24px;
      border-color: transparent transparent transparent #ffffff;
      z-index: 10;
    }
  }
}

.small-top {
  .content {
    width: 538px;
    padding-bottom: 52px;
    top: 0;
  }
}

.small-bottom-offset {
  .content {
    padding-top: 50px;
    width: 534px;
    padding-bottom: 60px;
    bottom: 0;
    left: -267px;
  }
  h2.p {
    font-style: italic;
    text-decoration: underline;
  }
}

.small-middle-offset {
  .content {
    padding-top: 50px;
    width: 534px;
    padding-bottom: 60px;
    bottom: 36%;
    left: -267px;
  }
}

.small-top-offset {
  .content {
    width: 534px;
    padding-bottom: 52px;
    top: 0;
    left: -267px;
    p {
      padding-top: 0;
      padding-bottom: 24px;
    }
    h2.p {
      padding-bottom: 10px;
    }
    h2.iu {
      text-decoration: underline;
      font-style: italic;
    }
  }
}

.height_100 .mCustomScrollBox {
  height: 100%;
}

.mCS-omgb-theme .mCSB_container {
  height: 100%;
}

.mCS-omgb-theme.mCSB_scrollTools.mCS-omgb-theme.mCSB_scrollTools_horizontal {
  height: 11px;
  background-color: black;
}

.mCS-omgb-theme .mCSB_dragger_bar {
  width: 174px;
  background-color: #e11b21;
}

.mCS-omgb-theme.mCSB_scrollTools .mCSB_draggerRail {
  display: none;
}

.mCS-omgb-theme.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
}

.mCS-omgb-theme.mCSB_scrollTools .mCSB_draggerContainer:hover {
  background-color: #000;
}

.mCS-omgb-theme.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  margin-top: 4px;
  background-color: #e11b21;
  width: 174px;
  border-radius: 0;
}

.mCS-omgb-theme.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #e11b21;
}

.mCS-omgb-theme.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-omgb-theme.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #e11b21;
}

.mCS-omgb-theme.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0;
}

.mCS-omgb-theme.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px;
}

.mCS-omgb-theme.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px;
}

.mCS-omgb-theme.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px;
}

.mCSB_scrollTools {
  z-index: 10;
}

@include mediaMobileW() {
  .o-gallery {
    .b-top-menu {
      .gallery-btn.gallery-btn_close {
        top: 6px;
        right: -20px;
      }
      .e-gallerey.gallery-btn__close {
        width: 40px;
        height: 30px;
        background-size: contain;
      }
      .b-top-menu__social {
        left: 6px;
        bottom: 20px;
        top: auto;
        right: auto;
        display: block;
        .c-anchors {
          margin-bottom: 4px;
        }
        .e-icon {
          width: 32px;
          height: 32px;
        }
      }
    }
    .info {
      width: 450px;
      font-size: 10px;
      line-height: 12px;
      padding: 60px 32px 0 32px;
      h1 {
        font-size: 18px;
        line-height: 21px;
      }
      p {
        padding-bottom: 18px;
      }
      h2 {
        font-size: 16px;
      }
      .number {
        font-size: 160px;
        bottom: -60px;
        margin-left: -25px;
      }
    }
    .avia {
      .avia-content {
        width: 450px;
        .avia-content-header {
          padding: 55px 10px 0 40px;
          height: 90px;
          font-size: 18px;
          .e-logo {
            bottom: 5px;
          }
        }
        .avia-content-info {
          padding-top: 22px;
          padding-left: 40px;
          p {
            font-size: 10px;
            padding-bottom: 10px;
            line-height: 12px;
          }
          ul {
            font-size: 10px;
            line-height: 12px;
            li span {
              margin-left: 0;
            }
          }
        }
        .avia-content-footer {
          margin-left: 40px;
          width: 350px;
          padding-top: 20px;
          bottom: 35px;
          img {
            width: 30px;
            padding: 5px;
          }
          span {
            padding-left: 50px;
            font-size: 14px;
            &.cost {
              font-size: 14px;
            }
          }
        }
        .btn {
          width: 140px;
          font-size: 12px;
          padding: 10px 20px;
          margin-top: 20px;
        }
        .avia-content-links {
          width: 370px;
          margin-left: 30px;
          font-size: 12px;
        }
      }
      .avia-tickets {
        top: 10%;
        width: 350px;
        .avia-tickets-header {
          width: 350px;
          height: 100px;
          background-size: contain;
        }
        .avia-tickets-content {
          padding: 16px 25px 30px 25px;
          span {
            font-size: 14px;
          }
          .cost .econom {
            width: 158px;
          }
          .cost .business {
            padding-left: 18px;
            width: 120px;
          }
        }
      }
    }
    .small-top,
    .small-bottom-offset,
    .small-top-offset,
    .small-middle-offset {
      .content {
        padding: 66px 34px 34px 34px;
        width: 400px;
        h2 {
          font-size: 16px;
        }
        p {
          font-size: 12px;
          line-height: 14px;
          padding-top: 16px;
        }
      }
    }

  }
  .mCS-omgb-theme.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 50px;
  }
}