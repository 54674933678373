.c-menu-item {
  width: 25%;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  color: $white;
  font-size: 0;
  text-decoration: none;

  &:hover {
    .c-menu-item__header {
      border-bottom-color: #ffffff;
      border-bottom-width: 3px;
    }

    .e-icon {
      background-color: $red;
      border-color: $red;
    }

    .c-menu-item__button {
      border-radius: 30px;
      border: 2px solid $red;
      padding-top: 0;
      padding-left: 0;
      background-color: $red;

      &:hover {
        background-color: $white;
        border-color: $white;
        color: $red;
      }

      .e-icon {
        opacity: 0;
      }
    }

    .c-menu-item__wrapper {
      &:before {
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }

  .e-icon {
    margin-right: 7px;
  }
}

.c-menu-item_black {
  color: $black;
  transition: color .5s;

  .e-icon {
    color: $black;
    border-color: rgba($darkGrey, 0.4);
  }

  .c-menu-item__header {
    border-bottom-color: rgba($darkGrey, 0.4);
  }

  .c-menu-item__button_icon {
    background-image: url(/img/d-plus.png);
  }

  .c-menu-item__number {
    color: $darkGrey;
    font-family: 'parangon_420';
  }

  &:hover {
    color: $white;

    .e-icon {
      color: $white;
      border-color: $white;
    }

    .c-menu-item__header {
      color: $black;
    }
  }
}

.c-menu-item_current {
  .c-menu-item__header {
    border-bottom-width: 3px;
  }

  &.c-menu-item_sidebar {
    @include grey();
  }

  &:hover {
    .c-menu-item__wrapper {
      @include grey();
    }
  }
}

.c-menu-item_sidebar {
  width: 100%;

  .e-icon {
    background-color: $grey;
    border-color: $grey;
  }

  .c-menu-item__wrapper {
    &:before {
      box-shadow: 0 0 0 200px rgba(0, 0, 0, 0.55) inset;
      opacity: 1;
    }

    z-index: inherit;
  }

  .c-menu-item__header {
    background-color: $white;
    color: $black;
  }

  &:hover {
    .c-menu-item__wrapper {

      &:before {
        transform: scale(1);
      }
    }
  }

}

.c-menu-item__wrapper {
  transition: background 2s;
  z-index: 1;
  position: relative;
  overflow: hidden;

  &:before {
    content: "";
    background-origin: border-box;
    background-position: top;
    box-shadow: 0 0 0 200px rgba(0, 0, 0, 0.4) inset;
    z-index: -1;
    opacity: 0;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    transition: opacity .5s ease-in-out, transform 5s ease-out 0s;
  }
}

.c-menu-item__wrapper_1 {
  &:before {
    background-image: url('/img/route1s.png');
  }
}

.c-menu-item__wrapper_2 {
  &:before {
    background-image: url('/img/route2s.png');
  }
}

.c-menu-item__wrapper_3 {
  &:before {
    background-image: url('/img/route3s1.png');
  }
}

.c-menu-item__wrapper_4 {
  &:before {
    background-image: url('/img/route4s1.jpg');
  }
}

.c-menu-item__header {
  height: 109px;
  padding-top: 33px;
  padding-left: 30px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 18px;
  font-style: italic;
  font-weight: bold;
  box-sizing: border-box;
  font-family: 'parangon_420';
}

.c-menu-item__number {
  position: relative;
  .e-icon {
    margin-right: 7px;
  }
}

.c-menu-item__body {
  padding: 50px 10px 0 50px;
  height: 287px;
  box-sizing: border-box;
}

.c-menu-item__title {
  font-size: 30px;
  margin-bottom: 15px;
  font-family: 'parangon_330';
}

.c-menu-item__text {
  font-size: 18px;
  font-family: 'parangon_230';
  font-style: italic;
  line-height: 1.3;
}

.c-menu-item__footer {
  padding: 0 30px;
  height: 73px;
  font-style: italic;
}

.c-menu-item__button {
  width: 204px;
  font-size: 14px;
  padding-top: 2px;
  padding-left: 2px;
}

.c-menu-item__about {
  display: inline-block;
  vertical-align: middle;
  text-align: right;
  width: 150px;
  margin-left: 110px;
  font-weight: normal;
  font-family: 'parangon_230';
}

.c-menu-item__button_icon {
  display: inline-block;
}

.c-menu-item__button-text {
  display: inline-block;
  vertical-align: top;
  padding-top: 16px;
}

@include mediaTabletW {
  .c-menu-item__text {
    font-size: 14px;
  }

  .c-menu-item__about {
    display: none;
  }
}

@media (max-width: 1575px) {
  .c-menu-item__title {
    font-size: 24px;
  }
}

@include mediaMobileW {
  .c-menu-item {
    width: 100%;

    .e-icon {
      margin-right: 6px;
    }
  }

  .c-menu-item__header {
    background: $white;
    color: $black;
    padding: 20px 0 0 6px;
    height: 68px;
    font-size: 14px;

    .e-icon {
      background-color: $red;
      border-color: $red;
    }
  }

  .e-icon {
    width: 28px;
    height: 28px;
    line-height: 27px;
    font-size: 10px;
  }

  .c-menu-item__wrapper {
    &:before {
      opacity: 1;
      background-size: cover;
      background-position: center;
    }
  }

  .c-menu-item__wrapper_1 {
    &:before {
      background-image: url('/img/route1t.jpg');
    }
  }

  .c-menu-item__wrapper_2 {
    &:before {
      background-image: url('/img/route2t.jpg');
    }
  }

  .c-menu-item__wrapper_3 {
    &:before {
      background-image: url('/img/route3t.png');
    }
  }

  .c-menu-item__wrapper_4 {
    &:before {
      background-image: url('/img/route4t.jpg');
    }
  }

  .c-menu-item__body {
    padding: 35px 20px 0;
    height: 190px;
  }

  .c-menu-item__title {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .c-menu-item__text {
    font-size: 14px;
    line-height: 1.2;
  }

  .c-menu-item__footer {
    height: 50px;
    padding: 0 4px;
  }

  .c-menu-item__button-text {
    padding: 9px 0 0 13px;
  }

  .c-menu-item_sidebar {
    .c-menu-item__button_icon {
      background-color: inherit;
    }
  }
}