.e-gallerey {
	display: inline-block;
	width: 68px;
	height: 64px;
	box-sizing: border-box;

	background-repeat: no-repeat;
	background-position: top;
	text-decoration: none;

	background-image: url('/img/icon-gallerey.png');
	
	&:hover {
		background-image: $img-icon-gallerey_hover;
	}
	&:active {
		background-image: url('/img/icon-gallerey_click.png');
	}
}