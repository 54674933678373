$primary-bg-color: #5cffe1;
$secondary-bg-color: #47a7f3;
$min-width: 320px;
$max-width: 768px;
$mobile-width-max: 1244px;
$white: #fff;
$whiteOpacity: rgba($white, 0.4);
$black: #000;
$blackOpacity: rgba($black, 0.7);
$blackGrey: #171717;
$darkGrey: #333333;
$grey: #727272;
$greyLight: #ECECEC;
$tundora: #424242;

$red: #df262a;
$redDarker: #ac162c;
$valhalla: #1E1645;

$heightHeader: 62px;
$heightHeaderHome: 103px;
$heightHeaderMobile: 38px;

$widthSidebar: 480px;
$widthSidebarSmall: 320px;

$paddingMobile: 65px 30px;

$animateSidebar: .8s;

$animateTime1: 2s;
$animateDelay1: .3s;

$animateTime2: 2s;
$animateDelay2: 2.3s;

$animateTime3: 2s;
$animateDelay3: 4.3s;

$animateTime4: 2s;
$animateDelay4: 5.3s;

$animateTimeShowHeader: .8s;
$animateTimeShowGallery: .8s;
$animateTimeShowGalleryPart2: .8s;
$animateDelayShowGalleryPart2: 1s;

$opacityBody: .5;