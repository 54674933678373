@include mediaDesktopW {
  .hidden-desktop {
    display: none !important;
  }

  .desktop {
    display: block !important;
  }
}

@include mediaMobileW {
  .hidden-mobile {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }
}
