.gallery-btn {
  position: relative;
  top: 20px;
  width: 100%;
  opacity: 1;
  will-change: opacity;
  transition: opacity $animateTime3 $animateDelay3;
}

.hidden{
  .gallery-btn{
    opacity: 0;
  }
}

.gallery-btn_m0 {
  .gallery-btn__icon {
    margin-bottom: 0;
  }
}

.gallery-btn__icon {
  margin: 0 auto;
  display: block;
  margin-bottom: 17px;
}

.gallery-btn__text {
  margin: 0 auto;
  text-align: center;
  width: 300px;
  color: $white;
  font-size: 14px;
}

.gallery-btn__text_bold {
  font-weight: bold;
}

@include mediaTabletH{
  .gallery-btn_m0{
    display: none;
  }
}