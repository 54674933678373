.c-anchors {
  padding: 4px;

  list-style-type: none;

  li {
    margin-bottom: 9px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  a{
    color: $white;
    text-decoration: none;
  }

  a:hover {
    cursor: pointer;
  }
}

.c-anchors_black {
  background: rgba(23, 23, 23, 0.8);;
  border-radius: 30px;

  .e-gallerey {
    background-image: url('/img/icon-gallerey_black.png');
  }
}

.c-anchors__gallerey {
  position: absolute;
  bottom: -80px;
  right: -8px;
}

@media (min-height: 1025px) {
  .c-anchors__gallerey {
    display: none;
  }
}