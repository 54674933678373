.button-down{
  height: 46px;
  width: 46px;
  display: none;
  bottom: 5%;
  border: 1px solid $white;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  margin-left: -23px;
}

.button-down__icon{
  height: 28px;
  width: 28px;
  position: relative;
  margin: 0 auto;
  border-radius: 50%;
  top: 7px;
  background: url(/img/w-toggle.png) $red no-repeat;
  background-position: center;
}