@import '_includes/normalize';
@import '_includes/vars';
@import '_includes/images';
@import '_includes/mixins';
@import '_includes/base';

@import '../fonts/proximanova/stylesheet';
@import '../fonts/parangon/stylesheet';
@import '../fonts/futuraFuturisCondBoldC';

@import "_elements/icon";
@import "_elements/icon-gallerey";
@import "_elements/logo";
@import "_components/anchor";
@import "_components/visa";
@import "_components/tagline";
@import "_components/footer";
@import "_components/mobileFooter";
@import "_components/index/gallery-btn";
@import "_components/index/mark";
@import "_components/index/buttonDown";
@import "_objects/sidebar";
@import "_blocks/top-menu";
@import "_objects/gallery";

html, body {
  height: 100%;
  font-family: "parangon_330";
}
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.scene{
  background: url("/img/main.jpg") no-repeat;
  width: 110%;
  height: 110%;
  position: absolute;
  background-size: cover;
}

.wrapper {
  min-height: 830px;
  min-width: $min-width;
  width: auto;
  position: relative;
  transition: box-shadow 0s .5s;
  overflow: hidden;
  height: 100%;

  &.fixed {
    display: none;
  }
}

.sidebar-open {
  .wrapper {
    opacity: .5;
  }
}

.b-top-menu__anchors_black {
  @extend .c-anchors_black;
}

.hashtag {
  position: absolute;
  width: 100%;
  height: 10%;
  will-change: transition;
  transition: top $animateTime1 $animateDelay1;
  top: 223px;

  .c-tagline__tagline {
    opacity: 1;
    will-change: opacity;
    transition: opacity $animateTime2 $animateDelay2;
  }

}

.hidden {
  .hashtag {
    top: -138px;

    .c-tagline__tagline {
      opacity: 0;
    }
  }

  .b-top-menu_black {
    background-color: rgba(23, 23, 23, 0);
  }
}

.o-gallery {
  position: fixed;
}

.home-content{
  min-height: 830px;
  position: relative;
  height: 100%;
}

@include mediaMobileW {
  .sidebar-open {
    .wrapper {
      opacity: 1;
    }
  }

  .wrapper {
    background-size: cover;
    background-position: top;
    height:100%;
    min-height: 400px;
  }

  .home-content{
    min-height: auto;
  }

  .hashtag {
    top: 25%;
  }

  .c-tagline__tagline-text {
    opacity: 1;
    will-change: opacity;
    transition: opacity $animateTime2 $animateDelay2;
  }

  .hidden{
    .c-tagline__tagline-text{
      opacity: 0;
    }
  }

  .gallery-btn_m0 {
    display: block;
  }

  .gallery-btn__icon {
    width: 50px;
    height: 39px;
    background-size: 39px;
  }

  .gallery-btn__text {
    font-size: 11px;
  }

  .b-top-menu {
    top: 0;
  }
}

