.c-visa {
	width: 223px;
	height: 51px;

	border-radius: 44px 0 0 44px;
	background: $red;
	font-size: 14px;

	.c-visa__flag {
		margin-top: 3px;
	}
	.c-visa__text {
		margin-left: 5px;
		margin-top: 8px;
	}
}

.c-visa__flag {
	display: block;
	float: left;
	width: 56px;
	height: 64px;
	font-style: normal;
	background-image: url('/img/flag.png');
	background-position: right top;
    background-repeat: no-repeat;
}

.c-visa__text {
	display: block;
	width: 102px;
	height: 30px;
	float: left;
	font-style: italic;
	color: $white;
	font-weight: 400;
	font-family: 'parangon_420';
	text-align: right;
	padding-right: 10px;
	box-sizing: border-box;
}

.c-visa__arrow {
	width: 50px;
	height: 51px;
	float: right;
	background-color: $white;
	background-image: url('/img/icon-arrow.png');
	background-repeat: no-repeat;
	background-position: center;
}

@include mediaMobileW() {
	.c-visa{
		height: 34px;
		width: 115px;

		.c-visa__text {
			font-size: 10px;
			margin: 6px 0 0;
			padding-right: 8px;
			width: 75px;
		}
	}

	.c-visa__flag{
		width: 37px;
		height: 60px;
		background-size: 40px;
	}
}