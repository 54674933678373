$heightTopMenu: 373px;

.b-top-menu {
  position: absolute;
  top: 0px;
  z-index: 3;

  width: 100%;
  height: 90px;

  list-style: none;

  .e-logo_britishairways {
    margin-top: -2.1px;
  }
}

.b-top-menu__great-britain {
  position: absolute;
  top: 28px;
  left: 50%;
  margin-left: -46px;
  opacity: 1;
  will-change: opacity;
  transition: opacity $animateTime2 $animateDelay2;
}

.hidden {
  .b-top-menu__great-britain {
    opacity: 0;
  }
}

.b-top-menu__ul {
  position: absolute;
  top: 30px;
  left: 29px;
  will-change: left;
  transition: left $animateTime2 $animateDelay2;

  list-style-type: none;

  li {
    float: left;
  }
}

.hidden {
  .b-top-menu__ul {
    left: -430px;
  }
}

.b-top-menu_vedomosti {
  margin-left: 26px;
  margin-top: -2.2px;
  &:after {
    position: relative;
    display: inline-block;
    content: ' ';
    width: 1px;
    height: 20px;
    background-color: $white;
    opacity: 0.22;
    margin-left: 22px;
    margin-right: 20px;

    top: -13px;
  }
}

.b-top-menu__anchors {
  position: absolute;
  top: 103px;
  left: 25px;
  transition: left $animateSidebar;
  will-change: left;
  z-index: 2;
}

.b-top-menu__social {
  position: absolute;
  top: 103px;
  right: 25px;
  opacity: 1;
  will-change: opacity;
  transition: opacity $animateTime3 $animateDelay3;
}

.hidden {
  .b-top-menu__social {
    opacity: 0;
  }
}

.b-top-menu__social_black {
  background: rgba(23, 23, 23, 0.8);
  border-radius: 30px;
  .e-gallerey {
    background-image: url('/img/icon-gallerey_black.png');
    &:hover{
      background-image: $img-icon-gallerey_hover_black
    }
  }
}

.b-top-menu__anchors_black {
  top: 103px;
  @extend .c-anchors_black;
}

.b-top-menu__visa_wrapper {
  position: absolute;
  top: 26px;
  right: 0;
  overflow: hidden;
  width: 222px;
  will-change: width;
  transition: width $animateTime2 $animateDelay2;
}

.hidden {
  .b-top-menu__visa_wrapper {
    width: 0;
    right: 0;
  }
}

.b-top-menu__visa {
  position: relative;
  right: -50px;
  transition: right .3s;

  &.open {
    right: 0;
    .c-visa__arrow {
      background-image: url('/img/visa-close.png');
    }
  }

  &:hover {
    right: 0;
  }
  &:active {
    .c-visa__arrow {
      background-color: $redDarker;
      background-image: url('/img/icon-arrow_click.png');
    }
  }
}

.b-top-menu_scroll {
  top: -$heightTopMenu;

  animation: menuGoBottom 1s ease-out;
  animation-fill-mode: forwards;
}

@keyframes menuGoBottom {
  0% {
    top: -$heightTopMenu;
  }
  100% {
    top: 0;
  }
}

.b-top-menu_scroll-top {
  top: 0;

  animation: menuGoTop 1s ease-out;
  animation-fill-mode: forwards;
}

@keyframes menuGoTop {
  0% {
    top: 0;
  }
  100% {
    top: -$heightTopMenu;
  }
}

.b-top-menu {
  .e-logo_omgb {
    display: none;
  }
}

.b-top-menu_mini {
  position: fixed;
  z-index: 15;

  height: $heightHeader;
  background-color: rgba(23, 23, 23, 0.9);

  .b-top-menu__anchors {
    top: 77px;
  }

  .b-top-menu__ul {
    top: 10px;
  }
  .b-top-menu__great-britain {
    top: 18px;
    margin-left: -118px;
  }
  /*.b-top-menu_vedomosti {
      margin-top: -2.2px;
      &:after {
          margin-left: 22px;
          margin-right: 20px;
          top: -13px;
      }
  }*/
  /*.e-logo_britishairways {
      margin-top: -2.1px;
  }*/
  .e-logo_great-britain {
    height: 37px;
    background-position: 0px -10px;
  }
  .e-logo_omgb {
    display: inline-block;
    margin-left: 23px;
    height: 35px;
  }
  .b-top-menu__visa_wrapper {
    top: 5px;
  }
}

.b-top-menu_black {
  @extend .b-top-menu_mini;
  position: absolute;
  background-color: rgba(23, 23, 23, 0.9);
  transition: background-color $animateTime2 $animateDelay2;
}

.b-top-menu_black {
  position: fixed;
  top: - $heightHeader;
  will-change: top;
  transition: top $animateTimeShowHeader;

  .b-top-menu__social {
    opacity: 0;
    position: fixed;
    right: -100px;
    transition: right $animateTimeShowHeader, opacity $animateTimeShowHeader;
    will-change: right, opacity;
  }
}

@include mediaMobileW {
  .b-top-menu {
    background-color: rgba($blackGrey, 0.9);
    height: $heightHeaderMobile;
    position: fixed;
  }

  .b-top-menu__social {
    display: none;
  }

  .b-top-menu__ul {
    left: 6px;
    top: 6px;
  }

  .hidden {
    .b-top-menu__ul {
      left: -105px;
    }
  }

  .o-sidebar {
    .b-top-menu__ul {
      display: none;
    }
  }

  .sidebar-btn {
    width: 28px;
    height: 28px;
    border-width: 1px;
    background-size: 12px;
    background-position: center;
  }

  .b-top-menu_vedomosti {
    margin-left: 9px;

    &:after {
      content: initial;
    }
  }

  .e-logo_vedomosti {
    width: 64px;
    height: 30px;
    background-size: cover;
  }

  .b-top-menu__great-britain {
    top: 12px;
    margin-left: -25px;
    height: 21px;
    overflow: hidden;
  }

  .e-logo_great-britain {
    width: 50px;
    height: 50px;
    background-size: 48px;
    position: relative;
    top: -4px;
  }

  .b-top-menu__visa_wrapper {
    top: 1px;
    width: 112px;
    height: 34px;
  }

  .b-top-menu__visa {
    right: 0;
  }

  .c-visa {
    height: 34px;
    width: 115px;

    .c-visa__text {
      font-size: 10px;
      margin: 6px 0 0;
      padding-right: 8px;
      width: 75px;
    }
  }

  .c-visa__flag {
    width: 37px;
    height: 60px;
    background-size: 40px;
  }
}

@include mediaMobileW {
  .sidebar-open {
    .sidebar-btn {
      background-image: url('/img/icon-close.png');
    }
  }
  .b-top-menu {
    z-index: 11;
    .b-top-menu__great-britain {
      top: 12px;
      margin-left: -60px;
    }
    .e-logo_omgb {
      height: 13px;
      background-size: contain;
    }
  }
}