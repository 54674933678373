.e-logo {
	display: inline-block;

	background-repeat: no-repeat;
	text-decoration: none;

}

.e-logo_great-britain {
	width: 92px;
	height: 65px;

	background-image: url('/img/great-britain.png');
}

.e-logo_vedomosti {
	width: 100px;
	height: 46px;
	background-image: url('/img/vedomosti.png');
}

.e-logo_britishairways {
	width: 211px;
	height: 46px;
	background-image: url('/img/britishairways.png');
}

.e-logo_omgb {
	width: 122px;
	height: 23px;
	background-image: url('/img/omgb-mini.png');
}
.e-logo_britishairways_small {
	width: 140px;
	height: 22px;
	background-image: url('/img/britishairways_small.png');
}

@include mediaMobileW() {
	.e-logo_vedomosti {
		width: 64px;
		height: 30px;
	}
}