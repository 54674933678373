@mixin mediaTabletW {
	@media only screen and (max-width: $mobile-width-max) {
		@content;
	}
}

@mixin mediaMobileW {
	@media only screen and (max-width: $mobile-width-max) {
		@content;
	}
}

@mixin mediaDesktopW {
	@media only screen and (min-width: $mobile-width-max + 1px) {
		@content;
	}
}

@mixin mediaTabletH {
	@media only screen and (max-height: 1024px) {
		@content;
	}
}

@mixin mediaMobileH {
	@media only screen and (max-height: 768px) {
		@content;
	}
}
@mixin mediaMobileW_xx {
	@media only screen and (max-width: $mobile-width-max) and (min-height: 769px) {
		@content;
	}
}
@mixin mediaMobileW_xm {
	@media only screen and (max-width: $mobile-width-max) and (max-height: 768px) {
		@content;
	}
}


@mixin font(){
	font-family: 'Times New Roman';
	font-family: 'proxima_nova_rgregular';
}

@mixin grey(){
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	-ms-filter: grayscale(100%);
	-o-filter: grayscale(100%);
	filter: grayscale(100%);
	filter: gray; /* IE 6-9 */

	will-change: filter;

	&:before {
		box-shadow: 0 0 0 200px rgba(0, 0, 0, 0.55) inset;
		opacity: 1;
	}
}

@mixin clearfix {
	&:before,
	&:after {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
	}
}