.c-mark {
  color: $white;
  position: absolute;
  right: 20px;
  bottom: 372px;
  transform: rotate(-90deg);
  width: 0;
  height: 0;
  overflow: visible;
  white-space: nowrap;

  opacity: 1;
  will-change: opacity;
  transition: opacity $animateTime3 $animateDelay3;
}

.hidden {
  .c-mark {
    opacity: 0;
  }
}

.c-mark__star {
  font-size: 22px;
  display: inline-block;
  position: relative;
  vertical-align: top;
  top: 3px;
  margin-right: 8px;
  height: 0;
}

.c-mark__text {
  font-size: 10px;
  font-style: italic;
  display: inline-block;
}

@include mediaMobileW {
  .c-mark {
    bottom: 10px;
    font-size: 0;
    right: 16px;
  }

  .c-mark__star {
    top: 0;
    margin-right: 2px;
  }

  .c-mark__text {
    font-size: 8px;
    padding-top: 2px;
  }
}