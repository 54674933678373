@import "menu-item";

.footer {
  bottom: 0;
  height: 469px;
  position: relative;
  opacity: 1;
  z-index: 1;
  overflow: hidden;
  will-change: height;
  transition: opacity $animateTime3 $animateDelay3, height $animateTime4 $animateDelay4;
}

.footer_under {
  position: absolute;
}

.hidden {
  .footer {
    opacity: 0;
    height: 76px;
  }
}

@include mediaMobileW {
  .footer {
    top: 100%;
    height: auto;
    opacity: 1;
    transition: none;

    &.top {
      top: 0;
      position: relative;
    }
  }

  .sidebar-open{
    .footer{
      display: none;
    }
  }
}