.e-icon {
	display: inline-block;
	width: 43px;
	height: 43px;
	box-sizing: border-box;

	text-align: center;
	line-height: 43px;
	font-style: none;

	border-radius: 50%;
	border: 2px solid rgba( 255, 255, 255, .4);
	background-repeat: no-repeat;
	background-position: center;
	text-decoration: none;
	color: $white;
	font-weight: bold;

	&:hover {
		border-color: $white;
	}
	&:active {
		background-color: $red;
		border-color: $red;
	}
}

.e-icon_menu {
	background-image: url('/img/sidebar.png');
}
.e-icon_home {
	background-image: url('/img/icon-home.png');
}
.e-icon_vk {
	background-image: url('/img/icon-vk.png');
}
.e-icon_twitter {
	background-image: url('/img/icon-twitter.png');
}
.e-icon_facebook {
	background-image: url('/img/icon-facebook.png');
}
.e-icon_active {
	background-color: $red;
	border-color: $red;
	&:hover {
		border-color: $red;
	}
}

.e-icon_arrow-prev,
.e-icon_plane-revert {
	transform: rotate(180deg);
}	
.e-icon_arrow-prev,
.e-icon_arrow-next {
	background-image: url('/img/icon-arrow_click.png');
	&:active {
		background-color: $white;
		border-color: $white;
		background-image: url('/img/icon-arrow.png');
	}
}
.e-icon_toggle {
	width: 16px;
	height: 9px;
	border: 0;
	background-image: url('/img/w-toggle.png');
	&:hover {
		background-image: url('/img/w-toggle_hover.png');
		background-color: transparent;
	}
}
.e-icon_replace {
	background-image: url('/img/w-replace.png');
}
.e-icon_datepicker {
	width: 19px;
	height: 19px;
	border-radius: 0;
	border: 1px solid rgba( 255, 255, 255, .4);

	background-image: url('/img/w-datepicker.png');
	&:active {
		background-color: transparent;
		border-color: $white;
	}
}
.e-icon_plane,
.e-icon_plane-revert {
	background-image: url('/img/w-plane.png');
	&:hover,
	&:active {
		border-color: rgba( 255, 255, 255, .4);
		background-color: transparent;
	}

}
.e-icon_plus {
  background-image: url('/img/w-plus.png');
  &:hover,
  &:active {
    border-color: rgba( 255, 255, 255, .4);
    background-color: transparent;
  }

}
.e-icon_man {
	background-image: url('/img/w-man.png');
}
.e-icon_child {
	background-image: url('/img/w-child.png');
}
.e-icon_baby {
	background-image: url('/img/w-baby.png');
}

.e-icon_close {
	background-image: url('/img/icon-close.png');
}