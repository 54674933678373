/* Generated by Font Squirrel (https://www.fontsquirrel.com) on March 26, 2017 */



@font-face {
    font-family: 'proxima_nova_blblack';
    src: url('../fonts/proximanova/proximanova-black.eot');
    src: url('../fonts/proximanova/proximanova-black.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proximanova/proximanova-black.woff2') format('woff2'),
         url('../fonts/proximanova/proximanova-black.woff') format('woff'),
         url('../fonts/proximanova/proximanova-black.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proxima_nova_rgbold';
    src: url('../fonts/proximanova/proximanova-bold.eot');
    src: url('../fonts/proximanova/proximanova-bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proximanova/proximanova-bold.woff2') format('woff2'),
         url('../fonts/proximanova/proximanova-bold.woff') format('woff'),
         url('../fonts/proximanova/proximanova-bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proxima_novabold_italic';
    src: url('../fonts/proximanova/proximanova-boldit.eot');
    src: url('../fonts/proximanova/proximanova-boldit.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proximanova/proximanova-boldit.woff2') format('woff2'),
         url('../fonts/proximanova/proximanova-boldit.woff') format('woff'),
         url('../fonts/proximanova/proximanova-boldit.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proxima_nova_ltlight';
    src: url('../fonts/proximanova/proximanova-light.eot');
    src: url('../fonts/proximanova/proximanova-light.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proximanova/proximanova-light.woff2') format('woff2'),
         url('../fonts/proximanova/proximanova-light.woff') format('woff'),
         url('../fonts/proximanova/proximanova-light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proxima_novalight_italic';
    src: url('../fonts/proximanova/proximanova-lightit.eot');
    src: url('../fonts/proximanova/proximanova-lightit.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proximanova/proximanova-lightit.woff2') format('woff2'),
         url('../fonts/proximanova/proximanova-lightit.woff') format('woff'),
         url('../fonts/proximanova/proximanova-lightit.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proxima_nova_rgregular';
    src: url('../fonts/proximanova/proximanova-reg.eot');
    src: url('../fonts/proximanova/proximanova-reg.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proximanova/proximanova-reg.woff2') format('woff2'),
         url('../fonts/proximanova/proximanova-reg.woff') format('woff'),
         url('../fonts/proximanova/proximanova-reg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proxima_novaregular_italic';
    src: url('../fonts/proximanova/proximanova-regit.eot');
    src: url('../fonts/proximanova/proximanova-regit.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proximanova/proximanova-regit.woff2') format('woff2'),
         url('../fonts/proximanova/proximanova-regit.woff') format('woff'),
         url('../fonts/proximanova/proximanova-regit.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'proxima_nova_thextrabold';
    src: url('../fonts/proximanova/proximanova-xbold.eot');
    src: url('../fonts/proximanova/proximanova-xbold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/proximanova/proximanova-xbold.woff2') format('woff2'),
         url('../fonts/proximanova/proximanova-xbold.woff') format('woff'),
         url('../fonts/proximanova/proximanova-xbold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}